import "@/styles/carousel-styles.css";
import "@/styles/typography.css"
// import dynamic from "next/dynamic";
import Script from "next/script";
import { SessionProvider } from "next-auth/react";
import { AppProps } from "next/app";
import Head from "next/head";
import { SnackbarProvider } from "notistack";
// import { darktheme, lighttheme } from "@/styles/theme";
// import { EmotionCache } from '@emotion/cache';

// import createCache from "@emotion/cache";

// function createEmotionCache() {
//   return createCache({ key: "css" });
// }
// Client-side cache, shared for the whole session of the user in the browser.
// const clientSideEmotionCache = createEmotionCache();

// interface FestecProps extends AppProps {
//   emotionCache?: EmotionCache;
//   themePrefernce: Number;
// }


export default function Festec(props: AppProps) {
  // console.log(mode);
  const {
    Component,
    // emotionCache = clientSideEmotionCache,
    pageProps: { session, ...pageProps },
  } = props;

  return (
    <>
      {/* <CacheProvider value={emotionCache}> */}
      <Head>
        <title>CometLabs</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="description" content="Build your Proof of Work or hire Top Notch Developers with CometLabs." />
        <meta name="description" content="Streamlining Developer Hiring with Cloud IDEs, Coding Insights for Seamless Assessments and Interviews" />
        <meta property="og:url" content="https://cometlabs.in" />
        {/* <meta property="og:image" content="https://cometlabs.in/_next/image?url=https%3A%2F%2Ffestec-bucket.s3.ap-south-1.amazonaws.com%2F31025a6a-8953-476b-b7fd-4238685ee160%2Fbad9f82b-cb22-4e92-a7ac-811d9026c71e_cometlabs.png&w=1920&q=75" /> */}
        {/* <meta property="og:image" content="/api/ogimage?url=https://cometlabs.in" /> */}
      </Head>
      <SessionProvider basePath={"/api/auth"} session={session}>
        <SnackbarProvider>
          <Component {...pageProps} />
        </SnackbarProvider>
      </SessionProvider>
      <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=G-EK5BRY9TL3`} />
      <Script strategy="lazyOnload" id="CometLabs">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-EK5BRY9TL3')
        `}
      </Script>
    </>
  );
}